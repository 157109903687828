export const IconPlus = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
      <circle id="Elipse_1" data-name="Elipse 1" cx="14" cy="14" r="14" fill="var(--color-primary)" />
      <g id="Grupo_1" data-name="Grupo 1" transform="translate(7.55 7.483)">
        <line id="Línea_2" data-name="Línea 2" y2="13.034" transform="translate(6.638)" fill="none" stroke="#fff" strokeWidth="2" />
        <line id="Línea_3" data-name="Línea 3" x2="12.901" transform="translate(0 6.493)" fill="none" stroke="#fff" strokeWidth="2" />
      </g>
    </svg>
  );
};
