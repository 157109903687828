export const IconAcross = () => {
  return (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 378.303 378.303">
      <polygon
        points="378.303,28.285 350.018,0 189.151,160.867 28.285,0 0,28.285 160.867,189.151 0,350.018 
	28.285,378.302 189.151,217.436 350.018,378.302 378.303,350.018 217.436,189.151 "
      />
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};
