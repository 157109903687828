import React, { useContext, useState } from "react";
import styled from "@emotion/styled";
import { Box, Fade, Modal, TextField } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import { DesktopDatePicker, TimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { WorkersContext } from "../../context/WorkersContext";

interface IModalNewDay {
  openModal: boolean;
  closeModal: any;
  workerId: string;
}

interface IValues {
  date: Date | string;
  initTime: Date | string | number;
  finishTime: Date | string;
}

const Container = styled.div`
  background-color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const ContainerInputs = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

const CustomButton = styled.button`
  background: none;
  border: 1px solid black;
  padding: 10px 15px;
  cursor: pointer;
`;

const ModalNewDay = (props: IModalNewDay) => {
  const { addNewDate } = useContext(WorkersContext);
  const { openModal, closeModal, workerId } = props;
  const [values, setValues] = useState<IValues>({
    date: "",
    initTime: "",
    finishTime: "",
  });
  const [newEntryTime, setNewEntryTime] = useState(0);
  const [newFinishTime, setNewFinishTime] = useState(0);

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "300px",
    outline: "none",
  };

  const handleChange = (e, typeDate) => {
    console.log(e);
    switch (typeDate) {
      case "date":
        setValues({
          ...values,
          [typeDate]: new Date(e),
        });
        break;
      case "initTime":
        let newInitTime = new Date(values.date).setHours(new Date(e).getHours());
        newInitTime = new Date(newInitTime).setMinutes(new Date(e).getMinutes());
        setNewEntryTime(newInitTime);
        break;

      case "finishTime":
        let newFinishTime = new Date(values.date).setHours(new Date(e).getHours());
        newFinishTime = new Date(newFinishTime).setMinutes(new Date(e).getMinutes());
        setNewFinishTime(newFinishTime);
        break;
    }
    setValues({
      ...values,
      [typeDate]: e? new Date(e):'',
    });
  };

  const exitModal = () => {
    resetValues();
    closeModal();
  };

  const resetValues = () => {
    setValues({
      date: "",
      initTime: "",
      finishTime: "",
    });
  };

  const handleSubmit = async () => {
    try {
      await addNewDate(workerId, newEntryTime, newFinishTime);
      exitModal();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      sx={{
        position: "fixed",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        width: "100vw",
        height: "100vh",
        backdropFilter: "blur(6px)",
        border: "none",
      }}
      onClose={exitModal}
      open={openModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModal}>
        <Box sx={modalStyle}>
          <Container>
            <h2>Añadir nuevo día</h2>
            <ContainerInputs>
              <DesktopDatePicker label="Fecha" inputFormat="dd/MM/yyyy" value={values.date} onChange={(e) => handleChange(e, "date")} renderInput={(params) => <TextField {...params} />} />
              <TimePicker label="Hora inicio" value={values.initTime} onChange={(e) => handleChange(e, "initTime")} renderInput={(params) => <TextField {...params} />} />
              <TimePicker label="Hora fin" value={values.finishTime} onChange={(e) => handleChange(e, "finishTime")} renderInput={(params) => <TextField {...params} />} />
              <CustomButton onClick={handleSubmit} disabled={!values.date || !values.initTime || !values.finishTime}>
                Añadir dia
              </CustomButton>
            </ContainerInputs>
          </Container>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ModalNewDay;
