const IconAdmin = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.75"
      height="33.25"
      viewBox="0 0 39.75 33.25"
    >
      <path
        id="manage_accounts_FILL0_wght400_GRAD0_opsz48"
        d="M20,23.75a7.193,7.193,0,0,1-7.5-7.5A7.193,7.193,0,0,1,20,8.75a7.193,7.193,0,0,1,7.5,7.5,7.193,7.193,0,0,1-7.5,7.5ZM4,39.8V35.1a5.821,5.821,0,0,1,.875-3.15A5.635,5.635,0,0,1,7.4,29.8a36.629,36.629,0,0,1,6.675-2.3A26.679,26.679,0,0,1,20,26.8h1.15a7.777,7.777,0,0,0-.45,1.375,14.73,14.73,0,0,0-.25,1.625H20a25.748,25.748,0,0,0-5.675.625A25.95,25.95,0,0,0,8.6,32.5a2.712,2.712,0,0,0-1.2,1.125A3.022,3.022,0,0,0,7,35.1v1.7H20.45a9.721,9.721,0,0,0,.6,1.625A7.843,7.843,0,0,0,21.9,39.8ZM33.35,42l-.5-3.3a8.871,8.871,0,0,1-1.725-.725A6.356,6.356,0,0,1,29.65,36.9l-2.75.6-1.25-2.1L28,33.2a7.862,7.862,0,0,1,0-2.5l-2.35-2.2,1.25-2.1,2.75.6a6.356,6.356,0,0,1,1.475-1.075A8.87,8.87,0,0,1,32.85,25.2l.5-3.3h2.7l.5,3.3a8.87,8.87,0,0,1,1.725.725A6.356,6.356,0,0,1,39.75,27l2.75-.6,1.25,2.1L41.4,30.7a7.863,7.863,0,0,1,0,2.5l2.35,2.2L42.5,37.5l-2.75-.6a6.356,6.356,0,0,1-1.475,1.075,8.87,8.87,0,0,1-1.725.725l-.5,3.3Zm1.35-6.05a3.779,3.779,0,1,0-2.9-1.1A3.921,3.921,0,0,0,34.7,35.95ZM20,20.75a4.361,4.361,0,0,0,4.5-4.5,4.361,4.361,0,0,0-4.5-4.5,4.361,4.361,0,0,0-4.5,4.5,4.361,4.361,0,0,0,4.5,4.5ZM20,16.25ZM20.45,36.8Z"
        transform="translate(-4 -8.75)"
        fill="var(--color-primary)"
      />
    </svg>
  );
};

export default IconAdmin;
