import React from "react";
import { Box, Fade, Modal, Backdrop } from "@mui/material";
import { Text } from "../genericComponents";
import styled from "@emotion/styled";

interface IGenericPopup {
  openModal: any;
  closeModal: any;
  textToShow: string;
}

const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "300px",
    outline: "none",
  };
  

  const ContainerKeyboard = styled.div`
  background-color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p{
    padding:0;
    margin: 0;
  }

`;


const GenericPopup = (props: IGenericPopup) => {
  const { openModal, closeModal, textToShow } = props;
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      sx={{
        position: "fixed",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        width: "100vw",
        height: "100vh",
        backdropFilter: "blur(6px)",
        border: "none",
      }}
      onClose={closeModal}
      open={openModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModal}>
        <Box sx={modalStyle}>
          <ContainerKeyboard>
            <Text>{textToShow}</Text>
          </ContainerKeyboard>
        </Box>
      </Fade>
    </Modal>
  );
};

export default GenericPopup;
