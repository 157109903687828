import styled from "@emotion/styled";
import React from "react";
import { IconSearch } from "../../icons/IconSearch";

const Container = styled.div`
  width: 100%;
  margin: 20px;
`;

const ContainerSearch = styled.div<any>`
  width: ${(props) => (props.customWidth ? props.customWidth : "200px")};
  height: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #f6f6f6;
  input {
    background: none;
    border: none;
    font-size: 14px;
    font-weight: 300;

    :focus {
      outline: none;
    }
  }
`;

const SearchBar = (props: { handleChange: any; value: string; customWidth?: string }) => {
  const { handleChange, value, customWidth } = props;
  return (
    <Container>
      <ContainerSearch customWidth={customWidth}>
        <input placeholder="Buscador de usuarios" type="text" value={value} onChange={(e) => handleChange(e.target.value)} />
        <IconSearch />
      </ContainerSearch>
    </Container>
  );
};

export default SearchBar;
