import styled from "@emotion/styled";
import React from "react";
import { IWorker } from "../../interfaces/IWorker";

interface IWorkerCard {
  worker: IWorker;
  setUserSelected: any;
}

const Card = styled.div`
  margin: 20px;
  width: 100px;
  height: 200px;
  cursor: pointer;
  img {
    width: 100%;
  }
`;

const ImgWorker = styled.div<any>`
  background-image: ${(props) => props.src? `url('${props.src}')`: 'url(https://firebasestorage.googleapis.com/v0/b/eurofilm-ptimer.appspot.com/o/avatar-ptimer.jpg?alt=media&token=e94115b3-e07f-426c-b9e2-a311ab924d5c)'};
  background-position: center;
  background-size: cover;
  width: 100px;
  height: 120px;
`;

const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px 0px;
  h2 {
    color: var(--color-primary);
    font-size: 17px;
    margin: 0;
    text-align: center;
  }
  p {
    color: black;
    font-size: 17px;
    font-weight: 300;
    margin: 0;
    text-align: center;
  }
`;

const WorkerCard = (props: IWorkerCard) => {
  const { worker, setUserSelected } = props;
  return (
    <Card onClick={() => setUserSelected(worker)}>
      <ImgWorker src={worker.image} />
      <ContainerText>
        <h2>{worker.name}</h2>
        <p>{worker.surname}</p>
      </ContainerText>
    </Card>
  );
};

export default WorkerCard;
