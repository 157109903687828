import styled from "@emotion/styled";
import React from "react";
import IconAdmin from "../../icons/IconAdmin";

interface IAdminButton {
  onClick: any;
}

const Container = styled.div`
  width: 62px;
  height: 62px;
  border-radius: 100%;
  background-color: white;
  position: absolute;
  right: 80px;
  bottom: 0px;
  transform: translateY(50%);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 6px #00000029;
`;

const AdminButton = (props: IAdminButton) => {
  return (
    <Container onClick={props.onClick}>
      <IconAdmin />
    </Container>
  );
};

export default AdminButton;
