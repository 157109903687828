export const IconErase = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18.207" height="14.161" viewBox="0 0 18.207 14.161">
      <path
        id="backspace_FILL0_wght400_GRAD0_opsz48"
        d="M14.294,20.975l2.832-2.832,2.832,2.832,1.087-1.087-2.857-2.807L21,14.274l-1.087-1.087-2.782,2.832-2.832-2.832-1.087,1.087,2.857,2.807-2.857,2.807ZM6,17.081l4.274-6.044a2.865,2.865,0,0,1,.784-.746A1.84,1.84,0,0,1,12.069,10H22.69a1.511,1.511,0,0,1,1.517,1.517V22.644a1.511,1.511,0,0,1-1.517,1.517H12.069a1.84,1.84,0,0,1-1.012-.291,2.865,2.865,0,0,1-.784-.746Zm1.9,0,3.894,5.563h10.9V11.517h-10.9Zm14.793,0v0Z"
        transform="translate(-6 -10)"
      />
    </svg>
  );
};
