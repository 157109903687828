import styled from "@emotion/styled";
import React, { useContext, useEffect, useState } from "react";
import { MainContainer } from "../../components/genericComponents";
import PinAccess from "../../components/PinAccess";
import SearchBar from "../../components/SearchBar";
import WorkersTable from "../../components/WorkersTable";
import { WorkersContext } from "../../context/WorkersContext";
import { IWorker } from "../../interfaces/IWorker";
import BigLogo from "../../logos/BigLogo";

const WelcomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const InnerContainer = styled.div`
  margin: 20px 0px;
  width: 100%;
  @media screen and (min-width: 400px) {
    width: 60%;
  }
`;

const ContainerTitle = styled.div`
  display: flex;
  flex-direction: column;
  h2 {
    margin: 10px 0px 0px 0px;
    font-size: 28px;
    text-align: center;
  }
  h3 {
    font-size: 17px;
    font-weight: 400;
    text-align: center;
    letter-spacing: 0px;
    color: #000000;
    text-align: center;
  }
`;

const workersToAdd = [];

const HomePage = () => {
  const { workers, addWorkersBatch } = useContext(WorkersContext);
  const [userSelected, setUserSelected] = useState<IWorker>(null);
  const [textToSearch, setTextToSearch] = useState("");
  const [filteredWorkers, setFilteredWorkers] = useState<IWorker[]>([]);

  useEffect(() => {
    let newFilteresWorkers = workers.filter((worker) => worker.name.toLowerCase().indexOf(textToSearch.toLowerCase()) !== -1);
    setFilteredWorkers(newFilteresWorkers);
  }, [textToSearch]);

  // const handleWorkers = async () => {
  //   workersToAdd.forEach(async (worker) => {
  //     await addWorkersBatch({ name: worker[0], surname: worker[1], dni: worker[2], ssNumber: "" });
  //   });
  // };

  return (
    <MainContainer column>
      <InnerContainer>
        <SearchBar handleChange={setTextToSearch} value={textToSearch} />
        <WelcomeContainer>
          <BigLogo />
          <ContainerTitle>
            <h2>Bienvenido a {process.env.REACT_APP_BUSINESS_NAME}</h2>
            <h3>Es el momento de registrar tu entrada</h3>
          </ContainerTitle>
          {/* <button onClick={handleWorkers}>Añadir trabajadores</button> */}
        <WorkersTable workers={textToSearch ? filteredWorkers : workers} setUserSelected={setUserSelected} />
        </WelcomeContainer>
        <PinAccess codeToCheck={userSelected?.pinCode} routeToAccess={`user/${userSelected?.id}`} openModal={userSelected ? true : false} closeModal={() => setUserSelected(null)} />
      </InnerContainer>
    </MainContainer>
  );
};

export default HomePage;
