import styled from "@emotion/styled";
import { Text, Input } from "../genericComponents";

type IInput = {
  title: string;
  name: string;
  type: string;
  value?: string;
  pattern?: string;
  width?: string;
  legendColor?: string;
  titlePattern?: string;
  icon?: any;
  onChange?: (value: any) => void;
  onClickSymbol?: () => void;
  disabled?: boolean;
  preIcon?: any;
  minDate?: boolean;
  disableMin?: boolean;
  initSelected?: number;
  step?: string;
  required?: boolean;
};
type IContainerInput = {
  width?: string;
  disabled?: boolean;
};
const ContainerDiv = styled.div<IContainerInput>`
  width: ${(props) => props.width};
  padding: 15px;
  opacity: ${(props) => props.disabled && 0.5};
`;
export const SpanRelative = styled.div`
  position: relative;
`;

export const ContainerTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  svg {
    margin-right: 10px;
  }
`;

const InputWithLegend = ({
  title,
  type,
  name,
  onChange,
  pattern,
  titlePattern,
  legendColor,
  width,
  value,
  icon,
  disabled,
  preIcon,
  initSelected,
  disableMin,
  step,
  required,
}: IInput) => {
  const today = new Date().toISOString().split("T")[0];
  const todaySelected =
    initSelected && new Date(initSelected).toISOString().split("T")[0];

  return (
    <ContainerDiv disabled={disabled} width={width}>
      <ContainerTitle>
        {preIcon}
        <Text color={legendColor} size="14px" top="10px" bottom="10px">
          {title}
        </Text>
      </ContainerTitle>
      <SpanRelative>
        <Input
          title={titlePattern}
          onChange={(e) => onChange && onChange(e)}
          type={type}
          name={name}
          step={step}
          value={value}
          disabled={disabled}
          min={!disableMin ? (initSelected ? todaySelected : today) : ""}
          required={required}
        />
        {icon}
      </SpanRelative>
    </ContainerDiv>
  );
};

export default InputWithLegend;
