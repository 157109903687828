import React, { useContext } from "react";
import styled from "@emotion/styled";
import { Box, Fade, Modal, Backdrop } from "@mui/material";
import { Text } from "../genericComponents";
import { CSVLink } from "react-csv";
import { WorkersContext } from "../../context/WorkersContext";
import { ExportCSV } from "../../utils/general";
import { IWorker } from "../../interfaces/IWorker";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "300px",
  outline: "none",
};

interface IPopupConfirm {
  openModal: boolean;
  closeModal: any;
  workerSelected: IWorker;
}

const ContainerKeyboard = styled.div`
  background-color: white;
  /* border: 1px solid var(--color-primary); */
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    margin: 0;
    font-size: 17px;
  }
  h3 {
    font-size: 17px;
    margin: 5px 0px;
    font-weight: 400;
  }
  input {
    width: 100%;
    text-align: center;
    background: none;
    border: none;
    font-size: 21px;
  }
`;

const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: centeR;
  width: 100%;
`;

const PopupConfirm = (props: IPopupConfirm) => {
  const { dataToDownload, dataBusiness } = useContext(WorkersContext);
  const { openModal, closeModal, workerSelected } = props;

  const customers = () => {
    let custs = [];
    custs.push({
      "Nombre Empresa": dataBusiness?.name,
      NIF: dataBusiness?.nif,
      Nombre: workerSelected?.name,
      Apellido: workerSelected?.surname,
      DNI: workerSelected?.dni,
      Antiguedad: workerSelected?.initDateToWork,
      "Número seguridad social": workerSelected?.ssNumber,
    });
    dataToDownload.forEach((dataDownload) => {
      custs.push({ Fecha: dataDownload[0], "Hora entrada": dataDownload[1], "Hora salida": dataDownload[2], "Horas trabajadas": dataDownload[3] });
    });
    return custs;
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      sx={{
        position: "fixed",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        width: "100vw",
        height: "100vh",
        backdropFilter: "blur(6px)",
        border: "none",
      }}
      onClose={closeModal}
      open={openModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModal}>
        <Box sx={modalStyle}>
          <ContainerKeyboard>
            <Text>¿Estás seguro de descargar el listado en excel?</Text>
            <ContainerButtons>
              {/* <CSVLinkCustom data={dataToDownload} onClick={() => closeModal()} filename={`listado-horas-${workerSelected}.csv`}>
                <Text>Sí</Text>
              </CSVLinkCustom> */}
              <ExportCSV closeModal={closeModal} csvData={customers()} fileName={"horas"} />
              <Text onClick={closeModal}>No</Text>
            </ContainerButtons>
          </ContainerKeyboard>
          {/* <Customers customers={customers()} /> */}
        </Box>
      </Fade>
    </Modal>
  );
};

export default PopupConfirm;
