import { Navigate, Outlet } from "react-router-dom";
import { useAuthState } from "../../context/FirebaseContext";
import Loading from "../Loading";

export const AnonRoute = ({ children }) => {
  const { isAuthenticated, loadingUser } = useAuthState();

  if (loadingUser) {
    return <Loading />;
  } else {
    if (isAuthenticated) {
      return <Navigate to={"/"} replace />;
    }

    return children ? children : <Outlet />;
  }
};
