import React, { useContext, useState } from "react";
import styled from "@emotion/styled";
import { MainContainer, Text } from "../../components/genericComponents";
import InputWithLegend from "../../components/InputWithLegend";
import InputButtonWithProps from "../../components/InputButton";
import { FirebaseContext } from "../../context/FirebaseContext";
import { getTextError } from "../../utils/firebaseUtils";

const SubContainer = styled.form`
  border: 1px solid #9d9d9d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const LoginPage = () => {
  const { login } = useContext(FirebaseContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    setError("");
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      await login(user.email, user.password);
    } catch (error) {
      let textError = getTextError(error.code);
      setError(textError);
    } finally {
      setLoading(false);
    }
  };

  return (
    <MainContainer column>
      <SubContainer onSubmit={handleSubmit}>
        <h2>Inicio de sesión</h2>
        <InputWithLegend
          title="Email"
          type="email"
          name="email"
          value={user.email}
          onChange={handleChange}
        />
        <InputWithLegend
          title="Contraseña"
          type="password"
          name="password"
          value={user.password}
          onChange={handleChange}
        />
        {loading ? (
          <InputButtonWithProps
            color="var(--color-primary)"
            text="Iniciando sesión..."
            type="submit"
          />
        ) : (
          <InputButtonWithProps
            color="var(--color-primary)"
            text="Iniciar sesión"
            type="submit"
          />
        )}
        {error && <Text color="red">{error}</Text>}
      </SubContainer>
    </MainContainer>
  );
};

export default LoginPage;
