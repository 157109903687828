export const IconArrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26.035" height="10.056" viewBox="0 0 26.035 10.056">
      <g id="Grupo_159" data-name="Grupo 159" transform="translate(-6507.5 59.278)">
        <path id="Polígono_1" data-name="Polígono 1" d="M5.028,0l5.028,9.642H0Z" transform="translate(6507.5 -49.222) rotate(-90)" />
        <rect id="Rectángulo_88" data-name="Rectángulo 88" width="18.183" height="2.479" transform="translate(6515.352 -55.49)" />
      </g>
    </svg>
  );
};
