import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Text } from "../components/genericComponents";

const camelCase = (str: string) => {
  return str.substring(0, 1).toUpperCase() + str.substring(1);
};

export const filterColumns = (data: any) => {
  const columns = Object.keys(data[0]);
  let headers: any = [];
  columns.forEach((col, idx) => {
    headers.push({ label: camelCase(col), key: col });
  });

  return headers;
};

export const ExportCSV = ({ csvData, fileName, closeModal }) => {
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Text
      onClick={(e) => {
        exportToCSV(csvData, fileName);
        closeModal();
      }}
    >
      Si
    </Text>
  );
};
