import styled from "@emotion/styled";
import React, { useContext, useState } from "react";
import { IconEdit } from "../../icons/IconEdit";
import { ITimeRegister } from "../../interfaces/IWorker";
import { MobileTimePicker, TimePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { IconTick } from "../../icons/IconTick";
import { IconAcross } from "../../icons/IconAcross";
import { WorkersContext } from "../../context/WorkersContext";
import InnerLoading from "../InnerLoading";

interface ICardHistoryTableProps {
  date: string;
  entryTime: string;
  finishTime: string;
  hoursWorked: string;
  timeRegister: ITimeRegister;
  idWorkerSelected: string;
}

const Container = styled.div<any>`
  display: flex;
  flex-direction: row;
  position: sticky;
  top: 0;
  left: 0;
  z-index: ${(props) => (props.header ? "11" : "10")};
`;

const Column = styled.div<any>`
  display: flex;
  align-items: center;
  flex: ${(props) => props.flex};
  background-color: ${(props) => (props.header ? "#f5f5f5" : "#9D9D9D05")};
  border-left: ${(props) => (props.border ? "1px solid #9d9d9d60" : "none")};
  padding: ${(props) => (props.padding ? props.padding : " 12px ")};
  h2 {
    margin: 0;
    font-size: 11px;
    font-weight: 600;
    text-align: center;
    @media screen and (min-width: 400px) {
      font-size: 13px;
    }
  }

  p {
    margin: 0;
    font-size: 11px;
    font-weight: 500;
    @media screen and (min-width: 400px) {
      font-size: 13px;
    }
  }
`;

const ContainerIconEdit = styled.div`
  width: 50px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    width: 50%;
  }
  svg {
    padding: 0px 5px;
  }
`;

const MobileTimePickerCustom = styled(MobileTimePicker)`
  padding: 0;
`;

const CardHistoryTable = ({ date, entryTime, finishTime, hoursWorked, timeRegister, idWorkerSelected }: ICardHistoryTableProps) => {
  const { updateTimeRegister } = useContext(WorkersContext);

  const [editMode, setEditMode] = useState(false);
  const [provisonalEntryDate, setProvisionalEntryDate] = useState(null);
  const [provisonalFinishDate, setProvisionalFinishDate] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleEdit = () => {
    if (!editMode) {
      let date = new Date().getTime();
      setProvisionalEntryDate(timeRegister.entryTime);
      if (timeRegister.finishTime) {
        setProvisionalFinishDate(timeRegister.finishTime);
      }
    }
    setEditMode(!editMode);
  };

  const handleClose = () => {
    setEditMode(false);
  };

  const confirmTime = async () => {
    try {
      setLoading(true);
      await updateTimeRegister(idWorkerSelected, timeRegister.id, provisonalEntryDate, provisonalFinishDate);
      setLoading(false);
      setEditMode(!editMode);
    } catch (error) {}
  };

  return (
    <Container>
      <Column flex={2}>{<p>{date}</p>}</Column>
      <Column border={true} flex={2}>
        {editMode ? (
          <MobileTimePicker
            value={provisonalEntryDate}
            onChange={(newValue) => {
              setProvisionalEntryDate(newValue.getTime());
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        ) : (
          <p>{entryTime}h</p>
        )}
      </Column>
      <Column border={true} flex={2}>
        {editMode ? (
          <MobileTimePicker
            value={provisonalFinishDate}
            onChange={(newValue) => {
              setProvisionalFinishDate(new Date(newValue).getTime());
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        ) : timeRegister.finishTime ? (
          <p>{finishTime}h</p>
        ) : (
          <p>Pendiente</p>
        )}
      </Column>
      <Column border={true} flex={2}>
        {!editMode && hoursWorked && <p>{hoursWorked}</p>}
      </Column>
      <Column padding="12px 10px" flex={0.1}>
        {editMode ? (
          loading ? (
            <InnerLoading />
          ) : (
            <ContainerIconEdit onClick={handleEdit}>
              <div onClick={confirmTime}>
                <IconTick />
              </div>
              <div onClick={handleClose}>
                <IconAcross />
              </div>
            </ContainerIconEdit>
          )
        ) : (
          <ContainerIconEdit onClick={handleEdit}>
            <IconEdit />
          </ContainerIconEdit>
        )}
      </Column>
    </Container>
  );
};

export default CardHistoryTable;
