import React, { createContext, useContext, useEffect, useState } from "react";
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, User } from "firebase/auth";
import { firebaseConfig } from "../fb/config";
import { initializeApp } from "firebase/app";
import { doc, getDoc, getFirestore } from "firebase/firestore";

export const FirebaseContext = createContext(null);

export const FirebaseProvider: any = ({ children }: any) => {
  const [user, setUser] = useState<User>(null);
  const [loadingUser, setLoadingUser] = useState(true);

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const auth = getAuth();

  useEffect(() => {
    const unsuscribe = onAuthStateChanged(
      auth,
      (userAuth) => {
        setUser(userAuth);
        setLoadingUser(false);
      },
      (error) => console.log(error)
    );
    return () => unsuscribe();
  }, []);

  const login = async (email: string, password: string) => {
    return await signInWithEmailAndPassword(auth, email, password);
  };

  const getAdminCode = async () => {
    const docRef = doc(db, "admin", "pinCode");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      let result = docSnap.data();
      return result.code;
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  };

  return (
    <FirebaseContext.Provider
      value={{
        user,
        loadingUser,
        login,
        getAdminCode,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

export const useAuthState = () => {
  const auth = useContext(FirebaseContext);
  return {
    ...auth,
    loadingUser: auth.loadingUser,
    isAuthenticated: auth.user != null,
  };
};
