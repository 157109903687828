export const IconDownload = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25.115" height="25.115" viewBox="0 0 25.115 25.115">
      <g id="file_download_black_24dp" transform="translate(0 0.008)">
        <g id="Grupo_65" data-name="Grupo 65" transform="translate(0 -0.008)">
          <rect id="Rectángulo_119" data-name="Rectángulo 119" width="25.115" height="25.115" fill="none" />
        </g>
        <g id="Grupo_66" data-name="Grupo 66" transform="translate(4.185 4.18)">
          <path
            id="Trazado_175"
            data-name="Trazado 175"
            d="M18.649,15.51v3.139H6.093V15.51H4v3.139a2.1,2.1,0,0,0,2.093,2.093H18.649a2.1,2.1,0,0,0,2.093-2.093V15.51ZM17.6,11.324,16.127,9.849l-2.71,2.7V4H11.324v8.549l-2.71-2.7L7.139,11.324l5.232,5.232Z"
            transform="translate(-4 -4)"
            fill="var(--color-primary)"
          />
        </g>
      </g>
    </svg>
  );
};
