import styled from "@emotion/styled";
import React, { useContext, useEffect, useState } from "react";
import { IWorker } from "../../interfaces/IWorker";
import InnerLoading from "../InnerLoading";
import WorkerCard from "../WorkerCard";
import { Text } from "../genericComponents";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin: 50px 0px;
  max-width: 700px;
  flex-wrap: wrap;
  justify-content: center;
`;

interface IWorkersTable {
  setUserSelected: any;
  workers: IWorker[];
}

const WorkersTable = (props: IWorkersTable) => {
  const { setUserSelected, workers } = props;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (workers.length > 0) {
      setLoading(false);
    }
  }, [workers]);

  const renderWorkersCards = () => {
    if (workers.length > 0) {
      return workers.map((worker, i) => {
        if (worker.active !== false) {
          return <WorkerCard key={`${i}-workerCard`} setUserSelected={setUserSelected} worker={worker} />;
        }
        return null;
      });
    } else {
      return <Text>No hay resultados.</Text>;
    }
  };

  return <Container>{loading ? <InnerLoading /> : renderWorkersCards()}</Container>;
};

export default WorkersTable;
