export const IconEdit = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20.713" height="16.173" viewBox="0 0 20.713 16.173">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectángulo_14" data-name="Rectángulo 14" width="13.618" height="13.595" fill="#9d9d9d" />
        </clipPath>
      </defs>
      <g id="Grupo_219" data-name="Grupo 219" transform="translate(-851.455 -363.25)">
        <g id="Grupo_165" data-name="Grupo 165" transform="translate(851.455 363.25)">
          <g id="Grupo_3" data-name="Grupo 3" clip-path="url(#clip-path)">
            <path
              id="Trazado_1"
              data-name="Trazado 1"
              d="M11.182,63.1c-.036-.033-.073-.064-.107-.1L2.916,54.855c-.031-.031-.059-.066-.076-.085L0,57.6c.016.017.051.055.088.092l8.159,8.143c.031.031.059.065.089.1h2.846V63.1"
              transform="translate(2.436 -52.342)"
              fill="#9d9d9d"
            />
            <path
              id="Trazado_2"
              data-name="Trazado 2"
              d="M207.315,2.658a1.375,1.375,0,0,0,.41.588c.367.359.728.723,1.09,1.088a.681.681,0,0,1,.078.118l2.85-2.845a.029.029,0,0,0,0-.016c-.456-.457-.907-.92-1.372-1.369a.748.748,0,0,0-1.1.042c-.519.514-1.032,1.033-1.552,1.546a1.312,1.312,0,0,0-.4.582Z"
              transform="translate(-207.315 0)"
              fill="#9d9d9d"
            />
          </g>
        </g>
        <path id="Trazado_177" data-name="Trazado 177" d="M0,0H12.29" transform="translate(859.877 378.923)" fill="none" stroke="#9d9d9d" stroke-width="1" />
      </g>
    </svg>
  );
};
