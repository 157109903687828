import styled from "@emotion/styled";
import React from "react";

const Container = styled.div`
  width: 250px;
  display: flex;
  align-items: center;

  img {
    width: 100%;
  }
`;

const BigLogo = () => {
  return (
    <Container>
      <img src="./assets/logo.png" alt="Logo Navbar" />
    </Container>
  );
};

export default BigLogo;
