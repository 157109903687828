import styled from "@emotion/styled";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MainContainer } from "../../components/genericComponents";
import Loading from "../../components/Loading";
import { WorkersContext } from "../../context/WorkersContext";
import { IconArrow } from "../../icons/IconArrow";
import { IconFilter } from "../../icons/IconFilter";
import { ITimeRegister, IWorker } from "../../interfaces/IWorker";
import moment from "moment";
import ModalDateFilter from "../../components/ModalDateFilter";
import InnerLoading from "../../components/InnerLoading";
import GenericPopup from "../../components/GenericPopup";

export const InnerContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const ContainerDetails = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: 500px) {
    flex-direction: row;
  }
`;

const ContainerHistory = styled.div`
  width: 100%;
`;

const ContainerTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  h2 {
    font-size: 13px;
    color: black;
    margin: 0;
    font-weight: 600;
  }
`;

const FilterButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* border: 1px solid #9d9d9d; */
  padding: 8px 10px;
  p {
    margin: 0;
    margin-right: 10px;
    font-size: 13px;
    font-weight: 500;
  }
`;

const ContainerPersonal = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const DetailsPersonal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 20px;
  h2 {
    margin: 0;
    color: var(--color-primary);
    font-size: 33px;
    font-weight: 600;
  }
  h3 {
    margin: 5px 0px 0px 0px;
    font-size: 33px;
    font-weight: 300;
  }

  h4 {
    color: #939393;
    font-size: 15px;
    font-weight: 600;
    margin: 15px 0px 0px 0px;
  }

  p {
    color: #939393;
    font-size: 15px;
    font-weight: 300;
    margin: 5px 0px 0px 0px;
  }
`;

const ContainerButtons = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  @media screen and (min-width: 400px) {
    flex-direction: row;
  }
`;

const ImgProfile = styled.div<any>`
  background-image: ${(props) =>
    props.src ? `url('${props.src}')` : "url(https://firebasestorage.googleapis.com/v0/b/eurofilm-ptimer.appspot.com/o/avatar-ptimer.jpg?alt=media&token=e94115b3-e07f-426c-b9e2-a311ab924d5c)"};
  background-position: center;
  background-size: cover;
  width: 150px;
  height: 220px;
`;

const ArrowBack = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  margin: 20px 0px;
  p {
    margin-left: 5px;
    color: #939393;
  }
`;

const LineSeparator = styled.div`
  height: 1px;
  width: 100%;
  background-color: black;
  margin: 30px 0px;
`;

const Button = styled.button<any>`
  color: ${(props) => (props.available ? "white" : "#9D9D9D")};
  background-color: ${(props) => (props.available ? "var(--color-primary)" : "white")};
  border: ${(props) => (props.available ? "1px solid var(--color-primary)" : "1px solid #9D9D9D")};
  padding: 8px 30px;
  font-size: 24px;
  font-weight: 600;
  margin: 20px;
  width: 180px;
`;

const ShowMoreButton = styled.button`
  margin: 0 auto;
  border: 1px solid var(--color-primary);
  background: none;
  padding: 8px 12px;
  color: var(--color-primary);
`;

const HistoryTable = styled.div`
  width: 100%;
  margin: 10px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  max-height: 400px;
  overflow: scroll;
`;

const CardHistoryTable = styled.div<any>`
  display: flex;
  flex-direction: row;
  width: 100%;

  div:first-of-type {
    border-left: none;
  }
`;

const ContainerFilters = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;

const CardDateFilter = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid #9d9d9d;
  /* padding: 8px 10px; */
  width: 120px;
  padding: 0px;

  p {
    margin: 0px;
    padding: 4px 0px;
  }

  span {
    color: var(--color-primary);
    margin-left: 10px;
  }
`;

const Column = styled.div<any>`
  flex: ${(props) => props.flex};
  background-color: ${(props) => (props.header ? "#9D9D9D19" : "#9D9D9D05")};
  border-left: 1px solid #9d9d9d60;
  padding: 12px 5px 12px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  h2 {
    margin: 0;
    font-size: 11px;
    font-weight: 600;
    text-align: center;
    @media screen and (min-width: 400px) {
      font-size: 13px;
    }
  }

  p {
    margin: 0;
    font-size: 11px;
    font-weight: 500;
    @media screen and (min-width: 400px) {
      font-size: 13px;
    }
  }
`;

const UserPage = () => {
  const { id } = useParams();
  const { getWorkersById, workers, timeRegisterWorker, getTimeRegisterById, getFilterTimeRegisterById, getFirebaseNextTimeRegister, updateTimeRegister, newTimeRegister } = useContext(WorkersContext);
  const navigation = useNavigate();

  const [workerToRender, setWorkerToRender] = useState<IWorker>(null);
  const [loading, setLoading] = useState(true);
  const [dateFilter, setDateToFilter] = useState<Date | undefined>(undefined);
  const [historyTableLoading, setHistoryTableLoading] = useState(false);
  const [openPicker, setOpenPicker] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [textModal, setTextModal] = useState("");

  useEffect(() => {
    getWorkerInfo();
  }, [workers]);

  useEffect(() => {
    const getFilterHistory = async () => {
      await getFilterTimeRegisterById(id, dateFilter.getTime());
      setHistoryTableLoading(false);
    };

    if (dateFilter && !openPicker) {
      setHistoryTableLoading(true);
      getFilterHistory();
    }
  }, [openPicker]);

  const getWorkerInfo = async () => {
    try {
      let worker = await getWorkersById(id);
      await getTimeRegisterById(id);
      setWorkerToRender(worker);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const checkPendingFinishTime = () => {
    if (timeRegisterWorker) {
      return timeRegisterWorker.find((timeRegister) => !timeRegister.finishTime);
    }
  };

  const cleanDateFilters = () => {
    setDateToFilter(undefined);
    getWorkerInfo();
  };

  const handleClick = async () => {
    let pendingFinishTime = checkPendingFinishTime();
    let newTime = new Date().getTime();
    if (pendingFinishTime) {
      showPopup("finish");
      await updateTimeRegister(id, pendingFinishTime.id, pendingFinishTime.entryTime, newTime);
    } else {
      showPopup("init");
      await newTimeRegister(id, newTime);
    }
  };

  const showPopup = (operation: string) => {
    if (operation === "init") {
      setTextModal("¡Bienvenido de nuevo!");
      setOpenModal(true);
    } else {
      setTextModal("¡Adiós!");
      setOpenModal(true);
    }

    setTimeout(() => {
      setTextModal("");
      setOpenModal(false);
      navigation("/");
    }, 2000);
  };

  const renderHistoryTable = () => {
    return timeRegisterWorker.map((timeRegister) => {
      let date = moment(timeRegister.entryTime).format("DD/MM/YYYY");
      let entryTime = moment(timeRegister.entryTime).format("HH:mm");
      let finishTime = moment(timeRegister.finishTime).format("HH:mm");
      var duration = moment.duration(moment(timeRegister.finishTime).diff(moment(timeRegister.entryTime)));
      var hours = duration.hours();
      var minutes = duration.minutes();

      return (
        <CardHistoryTable>
          <Column flex={2}>
            <p>{date}</p>
          </Column>
          <Column flex={2}>
            <p>{entryTime}h</p>
          </Column>
          <Column flex={2}>{timeRegister.finishTime ? <p>{finishTime}h</p> : <p>Pendiente</p>}</Column>
          <Column flex={2}>
            <p>{`${hours}h ${minutes}m`}</p>
          </Column>
        </CardHistoryTable>
      );
    });
  };

  if (loading) {
    return <Loading />;
  } else {
    return (
      <MainContainer>
        <InnerContainer>
          <ArrowBack onClick={() => navigation("/")}>
            <IconArrow />
            <p>Volver</p>
          </ArrowBack>
          <ContainerDetails>
            <ContainerPersonal>
              <ImgProfile src={workerToRender?.image} />
              <DetailsPersonal>
                <h2>{workerToRender?.name}</h2>
                <h3>{workerToRender?.surname}</h3>
                <h4>{workerToRender?.dni}</h4>
                <p>{workerToRender?.ssNumber}</p>
              </DetailsPersonal>
            </ContainerPersonal>
            <ContainerButtons>
              <Button onClick={handleClick} disabled={checkPendingFinishTime()} available={!checkPendingFinishTime()}>
                Registrar entrada
              </Button>
              <Button onClick={handleClick} disabled={!checkPendingFinishTime()} available={checkPendingFinishTime()}>
                Registrar salida
              </Button>
            </ContainerButtons>
          </ContainerDetails>
          <LineSeparator />
          <ContainerHistory>
            <ContainerTitle>
              <h2>Historial de registros</h2>
              <ModalDateFilter openModal={openPicker} closeModal={() => setOpenPicker(false)} setOpenModal={setOpenPicker} dateFilter={dateFilter} setDateFilter={setDateToFilter} />
            </ContainerTitle>
            <ContainerFilters>
              {dateFilter && (
                <CardDateFilter onClick={cleanDateFilters}>
                  <p>{moment(dateFilter).format("DD/MM/YYYY")}</p> <span>X</span>
                </CardDateFilter>
              )}
            </ContainerFilters>
            <HistoryTable>
              <CardHistoryTable header={true}>
                <Column header={true} flex={2}>
                  <h2>Fecha</h2>
                </Column>
                <Column header={true} flex={2}>
                  <h2>Hora entrada</h2>
                </Column>
                <Column header={true} flex={2}>
                  <h2>Hora salida</h2>
                </Column>
                <Column header={true} flex={2}>
                  <h2>Horas trabajadas</h2>
                </Column>
              </CardHistoryTable>
              {historyTableLoading ? <InnerLoading /> : renderHistoryTable()}
              {timeRegisterWorker.length === 25 && <ShowMoreButton onClick={() => getFirebaseNextTimeRegister(id)}>Cargar más</ShowMoreButton>}
            </HistoryTable>
          </ContainerHistory>
        </InnerContainer>
        <GenericPopup openModal={openModal} closeModal={() => setOpenModal(false)} textToShow={textModal} />
      </MainContainer>
    );
  }
};

export default UserPage;
