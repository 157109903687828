import styled from "@emotion/styled";
import GridLoader from "react-spinners/GridLoader";

const Container = styled.div`
  position: absolute;
  z-index: 101;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(6px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Loading = () => {
  return (
    <Container>
      <GridLoader color="var(--color-primary)" />
    </Container>
  );
};
export default Loading;
