import React, { createContext, useContext, useEffect, useState } from "react";
import { getFirestore, collection, query, onSnapshot, orderBy, doc, limit, getDoc, startAfter, getDocs, QuerySnapshot, DocumentData, updateDoc, addDoc, where } from "firebase/firestore";

import { firebaseConfig } from "../fb/config";
import { initializeApp } from "firebase/app";
import { ITimeRegister, IWorker } from "../interfaces/IWorker";
import moment from "moment";
import { FirebaseContext } from "./FirebaseContext";

export interface IWorkersContextProps {
  workers: IWorker[];
  timeRegisterWorker: ITimeRegister[];
  generateDataToDownload: (id: any, workerDetails: IWorker) => Promise<boolean>;
  dataToDownload: string[][];
  getWorkersById: (id: string) => Promise<IWorker>;
  getTimeRegisterById: (id: string) => Promise<void>;
  getFilterTimeRegisterById: (id: string, date: number) => Promise<void>;
  getFirebaseNextTimeRegister: (workerId: string) => Promise<void>;
  updateTimeRegister: (workerId: string, idTimeRegister: string, entryTime: number, finishTime: number) => Promise<void>;
  newTimeRegister: (workerId: string, entryTime: number) => Promise<void>;
  setTimeRegisterWorker: React.Dispatch<React.SetStateAction<ITimeRegister[]>>;
  addWorkersBatch: ({ name, surname, dni, ssNumber, initDateToWork }: { name: any; surname: any; dni: any; ssNumber: any; initDateToWork: string }) => Promise<void>;
  dataBusiness: { name: string; nif: string };
  addNewDate: (workerId: string, entryTime: number, finishTime: number) => Promise<void>;
}

export const WorkersContext = createContext<IWorkersContextProps>(null);

export const WorkersProvider: any = ({ children }: any) => {
  const [workers, setWorkers] = useState<IWorker[]>([]);
  const [timeRegisterWorker, setTimeRegisterWorker] = useState<ITimeRegister[]>([]);
  const [dataToDownload, setDataToDownload] = useState([[""]]);
  const { user } = useContext(FirebaseContext);
  const [dataBusiness, setDataBusiness] = useState<{ name: string; nif: string }>();
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  useEffect(() => {
    try {
      const q = query(collection(db, "workers"), orderBy("name", "asc"));
      onSnapshot(q, async (querySnapshot) => {
        const arr: any[] = await Promise.all(
          querySnapshot.docs.map((doc: any) => {
            return { id: doc.id, ...doc.data() };
          })
        );
        setWorkers(arr);
      });
    } catch (error) {
      console.log(error);
    }
  }, [db, user]);

  const getWorkersById = async (id: string) => {
    try {
      setTimeRegisterWorker([]);
      const q = doc(db, "workers", id);
      const docSnap: any = await getDoc(q);
      if (docSnap.exists()) {
        return { id: docSnap.id, ...docSnap.data() };
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addWorkersBatch = async ({ name, surname, dni, ssNumber, initDateToWork }) => {
    try {
      let code = Math.random() * (9999 - 1000 + 1) + 1000;

      await addDoc(collection(db, "workers"), {
        name: name,
        surname: surname,
        dni: dni,
        ssNumber: ssNumber,
        pinCode: "1234",
        initDateToWork: initDateToWork,
        // pinCode: code.toFixed(0),
      });
      console.log(`${name} ${surname}, pincode: ${code}`);
    } catch (error) {
      console.log(error);
    }
  };

  const getTimeRegisterById = async (id: string) => {
    try {
      const q = query(collection(doc(collection(db, "workers"), id), "timeRegister"), orderBy("entryTime", "desc"), limit(25));
      onSnapshot(q, (querySnapshot) => {
        const arr: ITimeRegister[] = [];
        querySnapshot.forEach(async (doc: any) => {
          const data = { ...doc.data(), id: doc.id };
          arr.push(data);
        });
        setTimeRegisterWorker(arr);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const generateDataToDownload = async (id, workerDetails) => {
    const arr: ITimeRegister[] = [];
    const docRef = doc(db, "admin", "dataBusiness");
    const docSnap = await getDoc(docRef);
    let queryDataBusiness: any = docSnap.data();
    const q = query(collection(doc(collection(db, "workers"), id), "timeRegister"), orderBy("entryTime", "desc"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(async (doc: any) => {
      const data = { ...doc.data(), id: doc.id };
      arr.push(data);
    });
    setDataBusiness(queryDataBusiness);
    createDataToDownload(arr, workerDetails);
    return true;
  };

  const createDataToDownload = (arr, workerDetails: IWorker) => {
    let arrayToDownload = [];
    arr.forEach((timeToRegister: ITimeRegister) => {
      let date = moment(timeToRegister.entryTime).format("DD/MM/YYYY");
      let entryTime = moment(timeToRegister.entryTime).format("HH:mm");
      let finishTime = moment(timeToRegister.finishTime).format("HH:mm");
      var duration = moment.duration(moment(timeToRegister.finishTime).diff(moment(timeToRegister.entryTime)));
      var hours = duration.hours();
      var minutes = duration.minutes();
      arrayToDownload.push([date, `${entryTime}h`, `${finishTime}h`, `${hours}h ${minutes}m`]);
    });
    setDataToDownload(arrayToDownload);
  };

  const getFilterTimeRegisterById = async (id: string, date: number) => {
    try {
      let dateInit = new Date(date);
      let dateFinish = new Date(date);
      dateInit.setHours(0, 0, 0, 0);
      dateFinish.setHours(23, 59, 59, 0);

      const q = query(
        collection(doc(collection(db, "workers"), id), "timeRegister"),
        where("entryTime", ">=", dateInit.getTime()),
        where("entryTime", "<=", dateFinish.getTime()),
        orderBy("entryTime", "desc"),
        limit(25)
      );
      onSnapshot(q, (querySnapshot) => {
        const arr: ITimeRegister[] = [];
        querySnapshot.forEach(async (doc: any) => {
          const data = { ...doc.data(), id: doc.id };
          arr.push(data);
        });
        setTimeRegisterWorker(arr);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getFirebaseNextTimeRegister = async (workerId: string) => {
    const next = query(
      collection(doc(collection(db, "workers"), workerId), "timeRegister"),
      orderBy("entryTime", "desc"),
      startAfter(timeRegisterWorker[timeRegisterWorker.length - 1].entryTime),
      limit(25)
    );
    const docSnap: QuerySnapshot<DocumentData> = await getDocs(next);

    if (docSnap.docs.length > 0) {
      const nextTimeRegisters: ITimeRegister[] = [];
      await docSnap.forEach((doc: any) => {
        nextTimeRegisters.push({ id: doc.id, ...doc.data() });
      });
      let newTime = timeRegisterWorker.concat(nextTimeRegisters);
      setTimeRegisterWorker(newTime);
    }
  };

  const updateTimeRegister = async (workerId: string, idTimeRegister: string, entryTime: number, finishTime?: number) => {
    try {
      if (finishTime) {
        await updateDoc(doc(db, "workers", workerId, "timeRegister", idTimeRegister), {
          entryTime: entryTime,
          finishTime: finishTime,
        });
      } else {
        await updateDoc(doc(db, "workers", workerId, "timeRegister", idTimeRegister), {
          entryTime: entryTime,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const newTimeRegister = async (workerId: string, entryTime: number) => {
    try {
      await addDoc(collection(doc(collection(db, "workers"), workerId), "timeRegister"), {
        entryTime: entryTime,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const addNewDate = async (workerId: string, entryTime: number, finishTime: number) => {
    await addDoc(collection(doc(collection(db, "workers"), workerId), "timeRegister"), {
      entryTime: entryTime,
      finishTime: finishTime,
    });
  };

  return (
    <WorkersContext.Provider
      value={{
        workers,
        timeRegisterWorker,
        getWorkersById,
        getTimeRegisterById,
        getFilterTimeRegisterById,
        getFirebaseNextTimeRegister,
        updateTimeRegister,
        newTimeRegister,
        setTimeRegisterWorker,
        dataToDownload,
        generateDataToDownload,
        addWorkersBatch,
        dataBusiness,
        addNewDate,
      }}
    >
      {children}
    </WorkersContext.Provider>
  );
};
