import React, { useState } from "react";
import { TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { es } from "date-fns/locale";
import { IconFilter } from "../../icons/IconFilter";
import styled from "@emotion/styled";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker/MobileDatePicker";

interface IDateFilter {
  dateFilter: Date | undefined;
  openModal: boolean;
  closeModal: any;
  setOpenModal:any;
  setDateFilter: any;
}

export const FilterButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid #9d9d9d;
  padding: 8px 10px;
  margin: 0px 5px;
  p {
    margin: 0;
    margin-right: 10px;
    font-size: 13px;
    font-weight: 500;
  }
`;

const ModalDateFilter = (props: IDateFilter) => {
  const { setDateFilter, dateFilter, closeModal, openModal, setOpenModal } = props;

  return (
    <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
      <MobileDatePicker
        label="Filtrar fecha"
        value={dateFilter ? dateFilter : null}
        onChange={(newValue) => {
          setDateFilter(newValue);
        }}
        open={openModal}
        onClose={closeModal}
        renderInput={(params) => (
          <FilterButton onClick={() => setOpenModal(true)}>
            <p>Filtrar fecha</p>
            <IconFilter />
          </FilterButton>
        )}
      />
    </LocalizationProvider>
  );
};

export default ModalDateFilter;
