import React from "react";
import ReactDOM from "react-dom/client";
import "./variables.css";
import "./normalize.css";
import { es } from "date-fns/locale";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { FirebaseProvider } from "./context/FirebaseContext";
import { WorkersProvider } from "./context/WorkersContext";
import { LocalizationProvider } from "@mui/x-date-pickers";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <BrowserRouter>
    <LocalizationProvider adapterLocale={es} dateAdapter={AdapterDateFns}>
      <FirebaseProvider>
        <WorkersProvider>
          <App />
        </WorkersProvider>
      </FirebaseProvider>
    </LocalizationProvider>
  </BrowserRouter>
);
