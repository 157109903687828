import React, { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { Text } from "../genericComponents";
import { Box, Fade, Modal } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import { IconErase } from "../../icons/IconErase";
import { ContainerKeyboard, ContainerNumbers, ContainerBlank } from "../PinAccess";
import { FirebaseContext } from "../../context/FirebaseContext";

interface IPinAccessAdmin {
  routeToAccess: string;
  openModal: boolean;
  closeModal: any;
}

const PinAccessAdmin = (props: IPinAccessAdmin) => {
  const { routeToAccess, openModal, closeModal } = props;
  const { getAdminCode } = useContext(FirebaseContext);

  const navigation = useNavigate();

  const [pinCode, setPinCode] = useState("");
  const [disableButtons, setDisableButtons] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const checkPin = async () => {
    try {
      setDisableButtons(true);
      let codeToCheck = await getAdminCode();
      if (pinCode.localeCompare(codeToCheck) === 0) {
        setSuccessMessage("Código correcto.");
        setTimeout(() => {
          navigation(routeToAccess);
          closeModal();
          cleanData();
        }, 1000);
      } else {
        setErrorMessage("Código incorrecto.");
        setTimeout(() => {
          cleanData();
        }, 1000);
      }
    } catch (error) {}
  };

  const cleanData = () => {
    setDisableButtons(false);
    setErrorMessage("");
    setSuccessMessage("");
    setPinCode("");
  };

  const handleClick = (e) => {
    e.preventDefault();
    let newPinCode = `${pinCode}${e.target.value}`;
    setPinCode(newPinCode);
  };
  const handleErase = (e) => {
    e.preventDefault();
    setPinCode(pinCode.slice(0, pinCode.length - 1));
  };

  useEffect(() => {
    if (pinCode.length === 4) {
      checkPin();
    }
  }, [pinCode]);

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "300px",
    outline: "none",
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      sx={{
        position: "fixed",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        width: "100vw",
        height: "100vh",
        backdropFilter: "blur(6px)",
        border: "none",
      }}
      onClose={closeModal}
      open={openModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModal}>
        <Box sx={modalStyle}>
          <ContainerKeyboard>
            <h2>Introduce PIN</h2>
            <h3>para acceder</h3>
            <input type="password" disabled value={pinCode} />
            {(successMessage || errorMessage) && <Text color={successMessage ? "green" : "red"}>{successMessage || errorMessage}</Text>}
            <ContainerNumbers>
              <div>
                <button disabled={disableButtons} value={1} onClick={handleClick}>
                  1
                </button>
                <button disabled={disableButtons} value={2} onClick={handleClick}>
                  2
                </button>
                <button disabled={disableButtons} value={3} onClick={handleClick}>
                  3
                </button>
              </div>
              <div>
                <button disabled={disableButtons} value={4} onClick={handleClick}>
                  4
                </button>
                <button disabled={disableButtons} value={5} onClick={handleClick}>
                  5
                </button>
                <button disabled={disableButtons} value={6} onClick={handleClick}>
                  6
                </button>
              </div>
              <div>
                <button disabled={disableButtons} value={7} onClick={handleClick}>
                  7
                </button>
                <button disabled={disableButtons} value={8} onClick={handleClick}>
                  8
                </button>
                <button disabled={disableButtons} value={9} onClick={handleClick}>
                  9
                </button>
              </div>
              <div>
                <ContainerBlank />
                <button value={0} onClick={handleClick}>
                  0
                </button>
                <ContainerBlank onClick={handleErase}>
                  <IconErase />
                </ContainerBlank>
              </div>
            </ContainerNumbers>
          </ContainerKeyboard>
        </Box>
      </Fade>
    </Modal>
  );
};

export default PinAccessAdmin;
