import styled from "@emotion/styled";
import React, { useState } from "react";
import Header from "../Header";
import PinAccessAdmin from "../PinAccessAdmin";

const Container = styled.div`
  margin: 0;
  padding: 0;
`;

const Layout = ({ children }: any) => {
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(!openModal);
  };

  return (
    <Container>
      <Header handleOpenModal={handleOpenModal} />
      {children}
      <PinAccessAdmin routeToAccess={`admin`} openModal={openModal} closeModal={() => setOpenModal(false)} />
    </Container>
  );
};

export default Layout;
