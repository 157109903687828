import React from "react";
import styled from "@emotion/styled";
import AdminButton from "../Buttons/AdminButton";
import NavbarLogo from "../../logos/NavbarLogo";

interface IHeader {
  handleOpenModal: any;
}

const Container = styled.div`
  width: 100%;
  height: 77px;
  position: absolute;
  background-color: var(--color-primary);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const Header = (props: IHeader) => {
  const { handleOpenModal } = props;
  return (
    <Container>
      <NavbarLogo />
      <AdminButton onClick={handleOpenModal} />
    </Container>
  );
};

export default Header;
