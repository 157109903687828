import styled from "@emotion/styled";
import React from "react";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  width: 130px;
  margin-left: 80px;
  display: flex;
  align-items: center;

  img {
    width: 100%;
    cursor: pointer;
  }
`;

const NavbarLogo = () => {
  const navigation = useNavigate();

  return (
    <Container>
      <img
        onClick={() => navigation("/")}
        src="/assets/logo-navbar.png"
        alt="Logo Navbar"
      />
    </Container>
  );
};

export default NavbarLogo;
