import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuthState } from "../../context/FirebaseContext";
import Loading from "../Loading";

const PrivateRoute = ({ children }) => {
  const { isAuthenticated, loadingUser } = useAuthState();
  if (loadingUser) {
    return <Loading />;
  } else {
    if (!isAuthenticated) {
      return <Navigate to="/login" replace />;
    } else {
      return children ? children : <Outlet />;
    }
  }
};

export default PrivateRoute;
