export const IconFilter = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16.165" height="15.811" viewBox="0 0 16.165 15.811">
      <path
        id="filter_alt_FILL0_wght400_GRAD0_opsz48"
        d="M17.7,16.894v5.929a.971.971,0,0,1-.988.988H14.736a.971.971,0,0,1-.988-.988V16.894l-5.9-7.535a.747.747,0,0,1-.1-.889A.812.812,0,0,1,8.51,8H22.938a.812.812,0,0,1,.766.469.747.747,0,0,1-.1.889Zm-1.976.1,5.929-7.51H9.8ZM15.724,16.993Z"
        transform="translate(-7.642 -8)"
        fill="var(--color-primary)"
      />
    </svg>
  );
};
