export const IconSearch = () => {
  return (
    <svg id="search_black_24dp_1_" data-name="search_black_24dp (1)" xmlns="http://www.w3.org/2000/svg" width="19.068" height="19.068" viewBox="0 0 19.068 19.068">
      <path id="Trazado_22" data-name="Trazado 22" d="M0,0H19.068V19.068H0Z" fill="none" />
      <path
        id="Trazado_23"
        data-name="Trazado 23"
        d="M12.931,11.74H12.3l-.222-.215a5.172,5.172,0,1,0-.556.556l.215.222v.628L15.712,16.9,16.9,15.712Zm-4.767,0A3.575,3.575,0,1,1,11.74,8.164,3.57,3.57,0,0,1,8.164,11.74Z"
        transform="translate(-0.616 -0.616)"
        fill="var(--color-primary)"
      />
    </svg>
  );
};
