import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { Text } from "../genericComponents";
import { Box, Fade, Modal } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import { IconErase } from "../../icons/IconErase";

export interface IPinAccess {
  codeToCheck: string;
  routeToAccess: string;
  openModal: boolean;
  closeModal: any;
}

export const ContainerKeyboard = styled.div`
  background-color: white;
  /* border: 1px solid var(--color-primary); */
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    margin: 0;
    font-size: 17px;
  }
  h3 {
    font-size: 17px;
    margin: 5px 0px;
    font-weight: 400;
  }
  input {
    width: 100%;
    text-align: center;
    background: none;
    border: none;
    font-size: 21px;
  }
`;

export const ContainerBlank = styled.div`
  width: 50px;
  height: 50px;
  margin: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContainerNumbers = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 10px;
  div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    button {
      width: 50px;
      height: 50px;
      margin: 3px;
      border-radius: 100%;
      border: 0.30000001192092896px solid var(--color-primary);
      background-color: #f8f8f8;
    }
  }
`;

const PinAccess = (props: IPinAccess) => {
  const { codeToCheck, routeToAccess, openModal, closeModal } = props;

  const navigation = useNavigate();

  const [pinCode, setPinCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const checkPin = () => {
    if (pinCode.localeCompare(codeToCheck) === 0) {
      setSuccessMessage("Código correcto.");
      setTimeout(() => {
        navigation(routeToAccess);
      }, 1000);
    } else {
      setErrorMessage("Código incorrecto.");
      setTimeout(() => {
        setErrorMessage("");
        setPinCode("");
      }, 1000);
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    let newPinCode = `${pinCode}${e.target.value}`;
    setPinCode(newPinCode);
  };
  const handleErase = (e) => {
    e.preventDefault();
    setPinCode(pinCode.slice(0, pinCode.length - 1));
  };

  useEffect(() => {
    if (pinCode.length === 4) {
      checkPin();
    }
  }, [pinCode]);

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "300px",
    outline: "none",
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      sx={{
        position: "fixed",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        width: "100vw",
        height: "100vh",
        backdropFilter: "blur(6px)",
        border: "none",
      }}
      onClose={closeModal}
      open={openModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModal}>
        <Box sx={modalStyle}>
          <ContainerKeyboard>
            <h2>Introduce PIN</h2>
            <h3>para acceder</h3>
            <input type="password" disabled value={pinCode} />
            {(successMessage || errorMessage) && <Text color={successMessage ? "green" : "red"}>{successMessage || errorMessage}</Text>}
            <ContainerNumbers>
              <div>
                <button value={1} onClick={handleClick}>
                  1
                </button>
                <button value={2} onClick={handleClick}>
                  2
                </button>
                <button value={3} onClick={handleClick}>
                  3
                </button>
              </div>
              <div>
                <button value={4} onClick={handleClick}>
                  4
                </button>
                <button value={5} onClick={handleClick}>
                  5
                </button>
                <button value={6} onClick={handleClick}>
                  6
                </button>
              </div>
              <div>
                <button value={7} onClick={handleClick}>
                  7
                </button>
                <button value={8} onClick={handleClick}>
                  8
                </button>
                <button value={9} onClick={handleClick}>
                  9
                </button>
              </div>
              <div>
                <ContainerBlank />
                <button value={0} onClick={handleClick}>
                  0
                </button>
                <ContainerBlank onClick={handleErase}>
                  <IconErase />
                </ContainerBlank>
              </div>
            </ContainerNumbers>
          </ContainerKeyboard>
        </Box>
      </Fade>
    </Modal>
  );
};

export default PinAccess;
