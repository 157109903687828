import styled from "@emotion/styled";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CardHistoryTable from "../../components/CardHistoryTable";
import { MainContainer, Text } from "../../components/genericComponents";
import InnerLoading from "../../components/InnerLoading";
import ModalDateFilter, { FilterButton } from "../../components/ModalDateFilter";
import SearchBar from "../../components/SearchBar";
import { WorkersContext } from "../../context/WorkersContext";
import { IconArrow } from "../../icons/IconArrow";
import { IconDownload } from "../../icons/IconDownload";
import { IWorker } from "../../interfaces/IWorker";
import { InnerContainer } from "../User";
import PopupConfirm from "../../components/PopupConfirm";
import { IconPlus } from "../../icons/IconPlus";
import ModalNewDay from "../../components/ModalNewDay";

const ArrowBack = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  margin: 20px 0px;
  p {
    margin-left: 5px;
    color: #939393;
  }
`;

const Container = styled.div`
  width: 100%;
  box-shadow: 2px 2px 5px #00000009;
  border: 1px solid #d6d6d6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media screen and (min-width: 400px) {
    flex-direction: row;
    height: 500px;
  }
`;

const ContainerSearchUsers = styled.div`
  width: 100%;
  border-right: 8px solid #c6c6c6;
  height: 100%;
  box-sizing: content-box;
  height: 300px;
  @media screen and (min-width: 400px) {
    width: 30%;
    height: 100%;
  }
`;

const ContainerDetailUsers = styled.div`
  width: 100%;
  @media screen and (min-width: 400px) {
    width: 70%;
  }
`;

const HeaderHistoryTable = styled.div<any>`
  display: flex;
  flex-direction: row;
  position: sticky;
  top: 0;
  left: 0;
  z-index: ${(props) => (props.header ? "11" : "10")};
`;

const ListWorkers = styled.div`
  width: 100%;
  height: calc(100% - 110px);
  overflow: scroll;

  div:nth-child(odd) {
    background-color: #9d9d9d13;
  }
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 60px;
  width: 100%;
  border-bottom: 1px solid #9d9d9d;
`;

const ButtonSearch = styled.div`
  height: 50px;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EmptyResults = styled.div`
  height: 50px;
  padding: 0px 20px;
  display: flex;
  align-items: center;
`;

const CardWorker = styled.div<any>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px 20px;
  height: 50px;

  background: ${(props) => props.selected && "var(--color-primary)!important"};

  h3,
  p {
    margin: 0;
    padding: 0;
    color: ${(props) => props.selected && "white"};
  }

  h3 {
    font-size: 13px;
    font-weight: 500;
  }

  p {
    font-size: 13px;
    font-weight: 300;
  }
`;

const ContainerFilter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 10px;
`;

const ContainerFilters = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  padding: 0px 10px;
`;

const CardDateFilter = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid #9d9d9d;
  /* padding: 8px 10px; */
  width: 120px;
  padding: 0px;

  p {
    margin: 0px;
    padding: 4px 0px;
  }

  span {
    color: var(--color-primary);
    margin-left: 10px;
  }
`;

const HistoryTable = styled.div`
  /* margin: 10px 30px 10px 30px; */
  display: flex;
  flex-direction: column;
  height: 370px;
  overflow: scroll;
  position: relative;
  @media screen and (min-width: 400px) {
    margin: 0px 5px;
  }
`;

const ContainerActions = styled.div<any>`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

const ContainerButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  svg {
    margin-right: 2px;
  }
  button {
    border: none;
    background: none;
    color: ${(props) => props.color};
  }
`;

const Column = styled.div<any>`
  flex: ${(props) => props.flex};
  background-color: ${(props) => (props.header ? "#f5f5f5" : "#9D9D9D05")};
  border-left: ${(props) => (props.border ? "1px solid #9d9d9d60" : "none")};
  padding: ${(props) => (props.padding ? props.padding : "12px")};
  display: flex;
  align-items: center;
  justify-content: center;
  h2 {
    margin: 0;
    font-size: 11px;
    font-weight: 600;
    text-align: center;
    @media screen and (min-width: 400px) {
      font-size: 13px;
    }
  }

  p {
    margin: 0;
    font-size: 11px;
    font-weight: 500;
    @media screen and (min-width: 400px) {
      font-size: 13px;
    }
  }
`;

const ContainerIconEdit = styled.div`
  width: 50px;
  display: flex;
  justify-content: center;
`;

const AdminPage = () => {
  const { workers, getTimeRegisterById, timeRegisterWorker, setTimeRegisterWorker, getFilterTimeRegisterById, dataToDownload, generateDataToDownload } = useContext(WorkersContext);
  const navigation = useNavigate();

  const [textToSearch, setTextToSearch] = useState("");
  const [workerSelected, setWorkerSelected] = useState<IWorker>(null);
  const [loadingTimeTable, setLoadingTimeTable] = useState(true);
  const [openPicker, setOpenPicker] = useState(false);
  const [openNewDayModal, setOpenNewDayModal] = useState(false);
  const [dateFilter, setDateToFilter] = useState<Date | undefined>(undefined);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [filteredWorkers, setFilteredWorkers] = useState<IWorker[]>([]);

  useEffect(() => {
    getTimeTable();
  }, [workerSelected]);

  useEffect(() => {
    setTimeRegisterWorker([]);
    setLoadingTimeTable(false);
  }, []);

  useEffect(() => {
    const getFilterHistory = async () => {
      await getFilterTimeRegisterById(workerSelected.id, dateFilter.getTime());
      setLoadingTimeTable(false);
    };

    if (dateFilter && !openPicker) {
      setLoadingTimeTable(true);
      getFilterHistory();
    }
  }, [openPicker]);

  const getTimeTable = async () => {
    try {
      if (workerSelected) {
        setLoadingTimeTable(true);
        const workerTimeTable = await getTimeRegisterById(workerSelected.id);
        setLoadingTimeTable(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = (worker: IWorker) => {
    setWorkerSelected(worker);
    cleanDateFilters();
  };

  const checkSelected = (worker: IWorker) => {
    if (workerSelected && workerSelected?.id.indexOf(worker.id) !== -1) {
      return true;
    } else {
      return false;
    }
  };

  const renderListWorkers = () => {
    if (textToSearch) {
      if (filteredWorkers.length > 0) {
        return filteredWorkers.map((worker, i) => {
          return (
            <CardWorker onClick={() => handleClick(worker)} selected={checkSelected(worker)} key={`${i}-cardWorker`}>
              <h3>
                {worker.name} {worker.surname}
              </h3>
              <p>{worker.dni}</p>
            </CardWorker>
          );
        });
      } else {
        return (
          <EmptyResults>
            <p>No hay resultados</p>
          </EmptyResults>
        );
      }
    } else {
      return workers.map((worker, i) => {
        return (
          <CardWorker onClick={() => handleClick(worker)} selected={checkSelected(worker)} key={`${i}-cardWorker`}>
            <h3>
              {worker.name} {worker.surname}
            </h3>
            <p>{worker.dni}</p>
          </CardWorker>
        );
      });
    }
  };

  const renderTimeTable = () => {
    return timeRegisterWorker.map((timeRegister) => {
      let date = moment(timeRegister.entryTime).format("DD/MM/YYYY");
      let entryTime = moment(timeRegister.entryTime).format("HH:mm");
      let finishTime = moment(timeRegister.finishTime).format("HH:mm");
      var duration = moment.duration(moment(timeRegister.finishTime).diff(moment(timeRegister.entryTime)));
      var hours = duration.hours();
      var minutes = duration.minutes();

      return <CardHistoryTable date={date} entryTime={entryTime} hoursWorked={`${hours}h ${minutes}m`} finishTime={finishTime} timeRegister={timeRegister} idWorkerSelected={workerSelected.id} />;
    });
  };

  const cleanDateFilters = () => {
    setDateToFilter(undefined);
    getTimeTable();
  };

  const handleClickExcel = () => {
    generateDataToDownload(workerSelected.id, workerSelected);
    setOpenConfirmModal(!openConfirmModal);
  };

  useEffect(() => {
    let newFilteresWorkers = workers.filter((worker) => worker.name.toLowerCase().indexOf(textToSearch.toLowerCase()) !== -1);
    setFilteredWorkers(newFilteresWorkers);
  }, [textToSearch]);

  return (
    <MainContainer>
      <InnerContainer>
        <ArrowBack onClick={() => navigation("/")}>
          <IconArrow />
          <p>Volver</p>
        </ArrowBack>
        <h2>Gestión de usuarios</h2>
        <Container>
          <ContainerSearchUsers>
            <ButtonSearch>
              <SearchBar customWidth={"100%"} handleChange={setTextToSearch} value={textToSearch} />
            </ButtonSearch>
            <ListWorkers>{renderListWorkers()}</ListWorkers>
          </ContainerSearchUsers>
          <ContainerDetailUsers>
            <ContainerFilter>
              <FilterButton onClick={()=>setOpenNewDayModal(true)}>
                <p>Añadir dia</p>
                <IconPlus />
              </FilterButton>
              <ModalDateFilter openModal={openPicker} closeModal={() => setOpenPicker(false)} setOpenModal={setOpenPicker} dateFilter={dateFilter} setDateFilter={setDateToFilter} />
            </ContainerFilter>
            <ContainerFilters>
              {dateFilter && (
                <CardDateFilter onClick={cleanDateFilters}>
                  <p>{moment(dateFilter).format("DD/MM/YYYY")}</p> <span>X</span>
                </CardDateFilter>
              )}
            </ContainerFilters>
            <HistoryTable>
              <HeaderHistoryTable header={true}>
                <Column header={true} flex={2}>
                  <h2>Fecha</h2>
                </Column>
                <Column border={true} header={true} flex={2}>
                  <h2>Hora entrada</h2>
                </Column>
                <Column border={true} header={true} flex={2}>
                  <h2>Hora salida</h2>
                </Column>
                <Column border={true} header={true} flex={2}>
                  <h2>Horas trabajadas</h2>
                </Column>
                <Column header={true} padding="12px 10px" flex={0.1}>
                  <ContainerIconEdit></ContainerIconEdit>
                </Column>
              </HeaderHistoryTable>
              {loadingTimeTable ? <InnerLoading /> : renderTimeTable()}
            </HistoryTable>
            <ContainerActions>
              <ContainerButton disabled={!workerSelected} onClick={handleClickExcel} color="var(--color-primary)">
                <IconDownload />
                <p>Exportar a excel</p>
              </ContainerButton>
            </ContainerActions>
          </ContainerDetailUsers>
        </Container>
      </InnerContainer>
      <PopupConfirm workerSelected={workerSelected} openModal={openConfirmModal} closeModal={handleClickExcel} />
      <ModalNewDay openModal={openNewDayModal} closeModal={()=>setOpenNewDayModal(false)} workerId={workerSelected?.id} />
    </MainContainer>
  );
};

export default AdminPage;
