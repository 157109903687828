import React from "react";
import { Route, Routes } from "react-router-dom";
import { AnonRoute } from "./components/AnonRoute";
import Layout from "./components/Layout";
import PrivateRoute from "./components/PrivateRoute";
import AdminPage from "./pages/Admin";
import HomePage from "./pages/Home";
import Login from "./pages/Login";
import UserPage from "./pages/User";

function App() {
  return (
    <Layout>
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <HomePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/login"
          element={
            <AnonRoute>
              <Login />
            </AnonRoute>
          }
        />
        <Route
          path="/admin"
          element={
            <PrivateRoute>
              <AdminPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/user/:id"
          element={
            <PrivateRoute>
              <UserPage />
            </PrivateRoute>
          }
        />
      </Routes>
    </Layout>
  );
}

export default App;
