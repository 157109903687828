import styled from "@emotion/styled";
import React from "react";
type IButton = {
  textColor?: string;
  color?: string;
  size?: number;
  fontSize?: number;
  text?: string;
  onClick?: any;
  onSubmit?: any;
  left?: string;
  right?: string;
  top?: string;
  bottom?: string;
  border?:string;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
};
const InputButton = styled.input<IButton>`
  color: ${(props) => (props.textColor ? props.textColor : "#fff")};
  background: ${(props) => (props.color ? props.color : "#000")};
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : "normal")};
  width: ${(props) => (props.size ? `${props.size}px` : "140px")};
  height: ${(props) => (props.size ? `${props.size}px` : "32px")};
  margin-top: ${(props) => (props.top ? props.top : 0)};
  margin-left: ${(props) => (props.left ? props.left : 0)};
  margin-bottom: ${(props) => (props.bottom ? props.bottom : 0)};
  margin-right: ${(props) => (props.right ? props.right : 0)};
  opacity: ${props => props.disabled && 0.5};
  border: ${props => props.border ? props.border:'none'};
  text-align:center;
  
  cursor: pointer;
`;
const InputButtonWithProps = ({
  textColor,
  color,
  size,
  fontSize,
  onClick,
  text,
  left,
  top,
  bottom,
  right,
  disabled,
  border,
  type,
  onSubmit
}: IButton) => {
  return (
    <InputButton
      textColor={textColor}
      color={color}
      size={size}
      fontSize={fontSize}
      top={top}
      left={left}
      bottom={bottom}
      right={right}
      disabled={disabled}
      type={type}
      value={text}
      border={border}
      required
      onClick={() => onClick && onClick()}
    />
      
    
  );
};

export default InputButtonWithProps;
